import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import DefaultTemplate from "@src/site/templates/default"
import { makeStyles } from "@material-ui/core/styles"
import { useTranslation } from "react-i18next"
import {
  detectAlpha2Country,
  detectAlpha2LanguageInPath,
} from "@src/utils/country"
import {
  Container,
  Grid,
  FormControl,
  InputLabel,
  Input,
} from "@material-ui/core"

// components
import Progress from "@src/components/Progress"
import Header from "@src/modules/HeaderModule"
import Footer from "@src/modules/FooterModule"
import Button from "@src/components/Button"
import Text from "@src/components/Text"
import Icon from "@src/components/Icon"

const { snake } = require(`../snake/core`)

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: "36px",
    width: "100%",
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(5),
  },
  description: {
    marginBottom: theme.spacing(3),
  },
  container: {
    width: "auto",
    paddingTop: theme.spacing(6),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(6),
    margin: theme.spacing(2),
  },
  element: {
    padding: theme.spacing(1),
    width: "100%",
  },
  formControl: {
    width: "100%",
    background: theme.customPalette.primaryGrey05,
    "& .MuiFormLabel-root.Mui-focused": {
      color: theme.customPalette.greyLight,
    },
    "& .MuiInputBase-root.MuiInput-underline:after": {
      borderBottom: `2px solid ${theme.customPalette.tangerine}`,
    },
    [theme.breakpoints.up("md")]: {
      "& .MuiFilledInput-root": {
        height: "42px",
      },
    },
  },
  input: {
    background: theme.customPalette.primaryGrey05,
    width: "100%",
    color: "black",
    fontSize: "14px",
    paddingLeft: "9px",
  },
  label: {
    color: theme.customPalette.greyLight,
    textTransform: "uppercase",
    paddingLeft: "9px",
    paddingTop: "4px",
    fontSize: "10px",
    zIndex: 1,
  },
  error: {
    color: theme.customPalette.coral,
    fontSize: "0.9rem !important",
  },
  button: {
    width: "100%",
    height: "45px",
  },
  icon: {
    marginBottom: theme.spacing(4),
  },
  p: {
    color: "black",
  },
}))

export default function StartCancellation({
  pageContext: { redirectCancellationUrl },
}) {
  const [pageModules, setPageModules] = useState({ header: {}, footer: {} })
  const [email, setEmail] = useState("")
  const [gproxy, setGproxy] = useState({
    sendRequest: false,
    waiting: true,
    success: false,
  })
  const classes = useStyles()

  const { i18n, t } = useTranslation()
  const country = detectAlpha2Country()
  const lang = detectAlpha2LanguageInPath()

  const page = {
    content: null,
    tracking: {},
    seo: {},
  }

  useEffect(() => {
    async function fetchData() {
      let site = t("site")
      const headerResult = await snake.getSectionByType("header", site)
      const footerResult = await snake.getSectionByType("footer", site)
      setPageModules({
        header: {
          ...headerResult.data.content,
          repo: { black: false, dark: true },
        },
        footer: { ...footerResult.data.content, css: footerResult.data.css },
      })
      // set country in gproxy lib
      if (window.gproxy)
        await window.gproxy(false, function () {
          window.gproxy.config.lang(lang)
        })
      setGproxy({ sendRequest: false, waiting: false, success: false })
    }

    fetchData()
  }, [])

  const handleChange = event => {
    setEmail(event.target.value)
  }

  const handleSubmit = event => {
    event.preventDefault()
    setGproxy({ sendRequest: false, waiting: false, success: false })
    const request = {
      email: email,
      url: `${window.location.origin}/${country}/${redirectCancellationUrl}`,
      country: country,
    }
    try {
      window.gproxy.gdpr.delete
        .request(request)
        .then(result => {
          setGproxy({ sendRequest: true, waiting: false, success: true })
        })
        .catch(error => {
          console.error("Error gproxy API", error)
          setGproxy({ sendRequest: true, waiting: false, success: false })
        })
    } catch (e) {
      console.error(e)
      setGproxy({ sendRequest: true, waiting: false, success: false })
    }
  }

  return (
    <DefaultTemplate page={page}>
      <Helmet>
        <script
          async
          type="text/javascript"
          src={`${process.env.GATSBY_GPROXY_URL}&lang=${lang}`}
        ></script>
      </Helmet>
      {gproxy.waiting ? (
        <Progress fullDevice></Progress>
      ) : (
        <>
          <Header {...pageModules.header} />
          <Container>
            {!gproxy.sendRequest || !gproxy.success ? (
              <Grid
                md={12}
                sm={12}
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.container}
              >
                <Grid
                  md={12}
                  direction="column"
                  justify="center"
                  alignItems="center"
                  className={classes.element}
                >
                  <h1 className={classes.title}>
                    {t("startCancellation.title")}
                  </h1>
                  <Text variant="bodyM" className={classes.description}>
                    {t("startCancellation.description")}
                  </Text>
                </Grid>
                <Grid
                  md={6}
                  sm={12}
                  direction="column"
                  justify="center"
                  alignItems="center"
                  className={classes.element}
                >
                  <FormControl className={classes.formControl}>
                    <InputLabel className={classes.label} htmlFor="email">
                      Email*
                    </InputLabel>
                    <Input
                      type="email"
                      id="email"
                      value={email}
                      onChange={handleChange}
                      className={classes.input}
                      required
                    />
                  </FormControl>
                  {gproxy.sendRequest && !gproxy.success && (
                    <Text variant="bodyM" className={classes.error}>
                      {t("startCancellation.responceError")}
                    </Text>
                  )}
                </Grid>
                <Grid
                  md={6}
                  sm={12}
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  className={classes.element}
                  style={{
                    marginBottom:
                      gproxy.sendRequest && !gproxy.success ? 23 : 0,
                  }}
                >
                  <Button
                    onClick={handleSubmit}
                    disabled={!email}
                    className={classes.button}
                  >
                    {t("startCancellation.button")}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid
                md={12}
                sm={12}
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.container}
              >
                <h1 className={classes.title}>
                  {t("startCancellation.title")}
                </h1>
                <Icon className={classes.icon} name="bullet_green" />
                <Text variant="bodyM">
                  {t("startCancellation.responceSuccess")}
                </Text>
              </Grid>
            )}
          </Container>
          <Footer {...pageModules.footer} />
        </>
      )}
    </DefaultTemplate>
  )
}
